<template>
    <div class="feedback">
        <!-- 帮助与反馈 -->
        <!-- 记录 -->
        <div v-show="feedbackStatus == 1" class="feedbackList">
            <PartitionTitle class="partitionTitle" title="意见反馈" />
            <div class="feedbackContent">
                <div class="feedbackTitle">
                    <span>历史反馈记录</span>
                    <!-- <button class="answerFeedbackBtn" @click="feedbackStatus = 3">问题反馈</button> -->
                </div>
                <div class="feedbackTop">
                    共{{ answerCount[0] }}个问题，{{ answerCount[1] }}个已回复，
                    {{ answerCount[2] }}个未回复
                </div>
                <div
                    class="feedbackList_content"
                    v-if="feedbackContent.length > 0"
                >
                    <div
                        v-for="item in feedbackContent"
                        :key="item.id"
                        @click="
                            getFeedbackQuestion(item.id, item.feedback_content)
                        "
                    >
                        <div class="feedbackList_text">
                            <span>{{ item.feedback_content }}</span>
                            <span>{{ item.created_at }}</span>
                        </div>
                        <div class="feedbackList_btns">
                            {{ item.reply_status_desc }}
                            <span
                                v-if="item.new_reply_count"
                                class="newReplyCount"
                                >{{ item.new_reply_count }}</span
                            >
                            <svg
                                v-else
                                width="10px"
                                height="18px"
                                viewBox="0 0 10 18"
                                version="1.1"
                            >
                                <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <g
                                        transform="translate(-1440.000000, -340.000000)"
                                        fill="#D5A561"
                                    >
                                        <g
                                            transform="translate(1440.000000, 340.000000)"
                                        >
                                            <path
                                                d="M8.77817459,1.29289322 C9.16869888,1.68341751 9.16869888,2.31658249 8.77817459,2.70710678 L2.41421356,9.07106781 L8.77817459,15.4350288 C9.16869888,15.8255531 9.16869888,16.4587181 8.77817459,16.8492424 C8.3876503,17.2397667 7.75448532,17.2397667 7.36396103,16.8492424 L0.292893219,9.77817459 C-0.0976310729,9.3876503 -0.0976310729,8.75448532 0.292893219,8.36396103 L7.36396103,1.29289322 C7.75448532,0.902368927 8.3876503,0.902368927 8.77817459,1.29289322 Z"
                                                id="路径备份-2"
                                                transform="translate(4.535534, 9.071068) scale(-1, 1) translate(-4.535534, -9.071068) "
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div
                        v-if="moreBtnStatus"
                        class="moreBtn_join"
                        @click="getMoreComment"
                    >
                        查看更多反馈记录
                    </div>
                    <p
                        v-else-if="
                            !moreBtnStatus && feedbackContent.length > 10
                        "
                        class="sideComment_noMore"
                    >
                        暂无更多评论
                    </p>
                </div>
                <div class="megBox" v-else>
                    <div>
                        <p>暂时没有更多记录</p>
                        <p>快去提交您的建议或问题吧</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 帮助 -->
        <div v-show="feedbackStatus == 2" class="feedbackHelp">
            <div class="feedbackContent">
                <div class="navTop" >
                    <span>当前位置：</span>
                    <span @click="feedbackBlack">意见反馈</span>
                    <i class="el-icon-arrow-right"></i>
                    <span>反馈记录</span>
                </div>
                <div class="feedbackTitle">
                    <span>反馈记录</span>
                </div>
                <div class="feedbackTop">
                    共{{ answerCount[0] }}个问题，{{ answerCount[1] }}个已回复，
                    {{ answerCount[2] }}个未回复
                </div>
                <div class="feedbackQuestion">
                    <div class="userList">
                        <div
                            v-if="
                                endPage != helpPage && endPage != 1 && endPage
                            "
                            class="userList_history"
                            @click="getMoreHistory"
                        >
                            查看历史记录
                        </div>
                        <div
                            v-if="endPage == helpPage && helpPage != 1"
                            style="text-align: center"
                        >
                            无更多历史记录
                        </div>
                        <ul>
                            <li v-for="item in feedbackAnswer" :key="item.id">
                                <div
                                    :class="
                                        item.user_type == 2
                                            ? 'userList_left'
                                            : 'userList_right'
                                    "
                                >
                                    <div class="userPhoto">
                                        <img
                                            :src="item.avatar"
                                            alt=""
                                            srcset=""
                                        />
                                    </div>
                                    <div class="userList_content">
                                        <div class="contentName">
                                            <span v-if="item.user_type == 2">{{
                                                item.user_name
                                            }}</span>
                                            <span>{{ item.created_at }}</span>
                                            <span v-if="item.user_type == 1">{{
                                                item.user_name
                                            }}</span>
                                        </div>
                                        <div>
                                            <span class="contentText">
                                                {{ item.content }}
                                                <span
                                                    v-if="item.is_new_reply"
                                                    class="msgRed_dot"
                                                    >新</span
                                                >
                                            </span>
                                        </div>
                                    </div>
                                    <div class="clearBoth"></div>
                                </div>
                            </li>
                            <div class="clearBoth"></div>
                        </ul>
                        <div class="clearBoth"></div>
                    </div>
                    <div class="helpAnswer">
                        <div>
                            <el-input
                                type="textarea"
                                show-word-limit
                                maxlength="300"
                                :autosize="{ minRows: 4, maxRows: 6 }"
                                placeholder="请输入问题描述"
                                v-model="answerDesc"
                            ></el-input>
                        </div>
                        <div class="answerSubmit">
                            <button @click="submitAnswer">提交</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 意见反馈 -->
        <div v-show="feedbackStatus == 3" class="feedbackSubmit">
            <div class="navTop" >
                <span>当前位置：</span>
                <span @click="feedbackBlack">意见反馈</span>
                <i class="el-icon-arrow-right"></i>
                <span>意见反馈</span>
            </div>
            <div class="feedbackContent">
                <div class="feedbackTitle">
                    <span>意见反馈</span>
                </div>
                <div class="feedbackTop">
                    共{{ answerCount[0] }}个问题，{{ answerCount[1] }}个已回复，
                    {{ answerCount[2] }}个未回复
                </div>
                <div class="feedbackQuestion">
                    <el-form label-width="80px">
                        <!-- form内容 Start-->
                        <div class="feed-back-content">
                            <div>
                                <label>反馈类型:</label>
                                <div>
                                    <el-radio
                                        v-for="(item, index) in feedType"
                                        :key="index"
                                        v-model="feedForm.type_id"
                                        :label="item.id"
                                        >{{ item.name }}</el-radio
                                    >
                                </div>
                            </div>
                            <div>
                                <label>反馈分类:</label>
                                <el-select
                                    v-model="feedForm.class_id"
                                    :popper-append-to-body="false"
                                    clearable
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in feedClassify"
                                        :key="item.id"
                                        :label="item.dict_detail"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div>
                                <label>反馈内容:</label>
                                <textarea
                                    v-model="feedForm.feedback_content"
                                    rows="5"
                                    placeholder="请输入反馈内容"
                                ></textarea>
                            </div>
                            <div>
                                <label class="minNone"></label>
                                <el-checkbox v-model="agreeStatus"
                                    >同意工作人员电话回访，回访时间：工作日9:00-18:00</el-checkbox
                                >
                            </div>
                        </div>
                        <!-- End -->
                    </el-form>
                    <div
                        slot="footer"
                        class="dialog-footer"
                        style="text-align: center"
                    >
                        <button class="answerSubmit" @click="submitFeedback">
                            提交
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PartitionTitle from "@/components/officialWebsite/PartitionTitle";
import commonRequest from '@/utils/common'
export default {
    props: {
        userMsgStatus: {
            //设置点击后加载请求
            type: Boolean,
            default: false,
        },
        msgBool: {
            type: Boolean,
            default: false,
        },
    },
    name: "Feedback",
    data() {
        return {
            feedbackStatus: true,
            nowId: 0,
            oldId: 0, //设置旧ID为了区分请求，同样内容只请求一次，新旧ID不一即发送请求
            nowQustion: "",
            activeNames: ["answer"],
            questionTitle: "",
            feedbackContent: [], // 历史反馈记录列表
            feedbackQuestion: [], // 反馈记录问题
            feedbackAnswer: [], // 反馈记录客服回答
            answerDesc: "", // 当前显示的输入内容
            answerContent: {}, // 反馈记录回答
            agreeStatus: false,
            feedForm: {
                type_id: 12, //类型
                class_id: "", //分类
                feedback_content: "", //内容
                agree_phone_callback: 0, //回访状态
            },
            answerCount: [0, 0, 0], //问题数，已回复，未回复统计
            feedType: [
                //类型储存
                { id: 12, name: "建议" },
                { id: 13, name: "问题" },
            ],
            feedClassify: [],
            helpPage: 1,
            endPage: 1,
            moreBtnStatus: true,
            count: 1,
            // 问题数
        };
    },
    mounted() {
        let wWidth = document.documentElement.clientWidth;
        if (
            (wWidth > 700 && this.msgBool == true) ||
            (wWidth < 700 && (this.msgBool == true) == false)
        ) {
            this.getHistoryListInfo();
        }
        
    },
    watch: {
        userMsgStatus(state) {
            if (!state) return;
            // 初始化反馈记录列表
            this.getHistoryListInfo();
        },
    },
    updated: function () {
        if (this.helpPage == 1) {
            this.$nextTick(function () {
                var p = document.querySelector(".userList");
                p.scrollTop = p.scrollHeight;
            });
        }
    },
    methods: {
        feedbackBlack() {
            // 返回重置
            this.activeNames = ["answer"];
            this.feedbackStatus = 1;
            this.helpPage = 1;
            this.oldId = 0;
        },
        getHistoryListInfo() {
            // 历史反馈列表
            this.$API.getHistoryList({ page: this.count }).then((res) => {
                if (res.data.status == 1) {
                    let { data } = res.data,
                        count = 0;
                    //重置数量
                    this.answerCount = [0, 0, 0];
                    if (this.count == 1) {
                        this.feedbackContent = data.list;
                    } else {
                        this.feedbackContent.push(...data.list);
                    }
                    // 合计数量
                    this.answerCount[0] = data.meta.count;
                    this.answerCount[1] = data.reply_count;
                    this.answerCount[2] = data.meta.count - data.reply_count;

                    for (let i = 0; i < data.list.length; i++) {
                        count += data.list[i].new_reply_count;
                    }
                    let limit = data.meta.limit,
                        total = data.meta.count;

                    if (this.count * limit >= total) {
                        this.moreBtnStatus = false;
                    }
                    this.$emit("feedbackHandler", count);
                }
            });
        },
        getMoreComment() {
            this.count++;
            this.getHistoryListInfo();
        },
        getMoreHistory() {
            // 获取更多历史
            this.helpPage++;
            this.getFeedbackQuestion(this.nowId, this.nowQustion, true);
        },
        getFeedbackQuestion(fid, qustion, revMore) {
            // 查看客服回复内容
            this.nowId = fid;
            this.nowQustion = qustion;

            this.$API
                .getHistoryQuestion({ fid, page: this.helpPage })
                .then((res) => {
                    if (res.data.status == 1) {
                        let { data } = res.data;
                        if (revMore) {
                            this.feedbackAnswer = [
                                ...data.list,
                                ...this.feedbackAnswer,
                            ];
                        } else {
                            this.feedbackAnswer = data.list;
                        }
                        this.questionTitle = qustion;
                        this.endPage = data.meta.last_page;
                        this.feedbackStatus = 2;

                        // 刷新用户消息
                        commonRequest.get_user_msg_list();
                    }
                });
        },
        reload() {
            // 重置反馈提交数据
            this.feedForm = {
                type_id: 12,
                agree_phone_callback: 0,
                feedback_content: "",
                class_id: this.feedClassify[0].id,
            };
            this.agreeStatus = false;
        },
        getFeedBackClassify() {
            if (this.feedClassify.length == 0) {
                this.$API.getFeedBackClassify().then((response) => {
                    let res = response.data;
                    if (res.status == 1) {
                        // 默认值渲染
                        this.feedClassify = res.data;
                        this.feedForm.class_id = res.data[0].id;
                    }
                });
            }
        },
        submitFeedback() {
            // 意见反馈提交
            if (!!this.feedForm.feedback_content) {
                this.agreeStatus
                    ? (this.feedForm.agree_phone_callback = 1)
                    : "";
                this.$API.submitFeedBack(this.feedForm).then((response) => {
                    let res = response.data;
                    if (res.status == 1) {
                        // 成功
                        this.$emit("controlDialog", false);
                        this.$store.commit("MESSAGE_TYPE", true);
                        this.$store.commit("MESSAGE_TEXT", "提交反馈成功！");
                        // input 复原
                        this.reload();
                        // 获取提交成功后的新数据
                        this.getHistoryListInfo();
                        // 跳回首页
                        this.feedbackStatus = 1;
                    }
                });
            } else {
                this.$store.commit("MESSAGE_TEXT", "请输入反馈内容！");
            }
        },
        submitAnswer() {
            // 反馈回答提交
            if (!!this.answerDesc) {
                this.$API
                    .submitFeedbackAnswer({
                        fid: this.nowId,
                        content: this.answerDesc,
                    })
                    .then((res) => {
                        if (res.data.status == 1) {
                            this.answerDesc = "";
                            this.helpPage = 1;
                            this.getFeedbackQuestion(
                                this.nowId,
                                this.nowQustion
                            );
                            if (!this.activeNames.includes("qustion"))
                                this.activeNames.push("qustion");
                        }
                    });
            } else {
                this.$store.commit("MESSAGE_TEXT", "请输入反馈内容！");
            }
        },
        parentEvent(){
            this.feedbackBlack();
        }
    },
    components: {
        PartitionTitle,
    },
};
</script>

<style lang="scss" scoped>
.feedback {
    padding: 0 40px;
    padding-top: 48px;
    padding-bottom: 20px;
    .navTop {
        font-size: 14px;
        color: #555555;
        :nth-child(1) {
            color: #b4b4b4;
        }
        :nth-child(2):hover {
            cursor: pointer;
            color: #e3af6d;
            text-decoration: line;
        }
    }
    .feedbackContent {
        width: 100%;
        clear: both;
        .feedbackTitle {
            position: relative;
            text-align: center;
            margin-top: 15px;
            padding-bottom: 16px;
            border-bottom: 1px solid #d2bda0;
            > span {
                font-size: 18px;
                color: #d5a561;
                font-weight: bold;
            }
            .answerFeedbackBtn {
                width: 70px;
                height: 28px;
                font-size: 12px;
                border-radius: 4px;
                position: absolute;
                left: 0;
                border: none;
                outline: none;
                color: #ffffff;
                background: linear-gradient(154deg, #fad8a1 0%, #e3af6d 100%);
            }
            .answerFeedbackBtn:hover {
                background: linear-gradient(154deg, #fdd189 0%, #e2a556 100%);
            }
            .blackLeft {
                position: absolute;
                left: 0;
                margin-top: 3px;
                > svg {
                    margin-top: -2px;
                }
                > span {
                    color: #606062;
                }
            }
            .blackLeft:hover {
                cursor: pointer;
                > svg .blackLeft_svg {
                    fill: #828283cb;
                }
                > span {
                    color: #828283cb;
                }
            }
        }
        .feedbackTop {
            padding: 9px 0;
            font-size: 16px;
            color: #d5a561;
            text-align: center;
            border-bottom: 1px solid #d2bda0;
        }
    }
}
.feedbackList {
    .feedbackList_content {
        margin-top: 45px;
        > div {
            height: 72px;
            margin-top: 16px;
            border: 1px solid #fad8a1;
            background-color: #ffffff;
            .feedbackList_text {
                float: left;
                width: 85%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; //值为clip表示剪切，ellipsis时为显示省略号
                > span {
                    display: block;
                    font-weight: 400;
                    margin-left: 24px;
                }
                > span:nth-child(1) {
                    font-size: 16px;
                    margin-top: 14px;
                }
                > span:nth-child(2) {
                    font-size: 12px;
                    color: #adadb0;
                }
            }
            .feedbackList_btns {
                float: right;
                width: 13%;
                height: 72px;
                font-size: 16px;
                color: #d5a561;
                line-height: 72px;
                // margin-right: 26px;
                vertical-align: middle;
                position: relative;
                > svg {
                    margin-left: 8px;
                    margin-top: -3px;
                }
                .newReplyCount {
                    height: 16px;
                    position: absolute;
                    top: 28px;
                    left: 55px;
                    border-radius: 9999rem;
                    color: #fff;
                    padding: 0 5px;
                    font-size: 12px;
                    font-weight: 400;
                    display: inline-block;
                    background-color: #ff6a6a;
                    text-align: center;
                    line-height: 16px;
                    // float:right;
                }
            }
        }
        > div:hover {
            border: 1px solid #d5a561;
            cursor: pointer;
        }
        .moreBtn_join {
            height: 30px;
            color: #777;
            font-weight: bold;
            text-align: center;
            background: none;
            border: none;
        }
        .moreBtn_join:hover {
            background: none;
            border: none;
            color: #d5a561;
        }
        > p {
            font-weight: bold;
            margin-top: 20px;
            text-align: center;
        }
    }
    .megBox {
        margin-top: 20%;
        text-align: center;
        color: #a2a2a2;
    }
}
.feedbackHelp {
    .feedbackQuestion {
        margin-top: 45px;
        margin-bottom: 20px;
        .userList {
            height: 340px;
            padding: 10px;
            overflow-y: auto;
            background-color: #fff;
            border-top: 1px solid #d2bda0;
            .userList_history {
                text-align: center;
            }
            .userList_history:hover {
                cursor: pointer;
                color: #d5a561;
            }

            ul {
                margin-bottom: 0;
                padding-left: 0;
            }
        }

        .userList_left,
        .userList_right {
            width: 100%;
            margin-bottom: 20px;
            > .userList_content {
                width: 620px;
                .contentName {
                    color: #a3a2a2;
                    margin-bottom: 5px;
                }
            }
            > .userPhoto {
                width: 50px;
                height: 50px;
                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        .clearBoth {
            clear: both;
        }
        .userList_left {
            .userPhoto,
            .userList_content {
                position: relative;
                float: left;
                .contentName {
                    font-size: 14px;
                    :nth-child(1) {
                        font-weight: bold;
                        margin-right: 10px;
                    }
                }
                .contentText {
                    position: relative;
                    padding: 5px 12px;
                    display: inline-block;
                    border: 1px solid #e6e6e6;
                    border-radius: 4px;
                    background-color: #f0f0f0;
                    white-space: normal;
                    word-break: break-all;
                    word-wrap: break-word;
                }
                .msgRed_dot {
                    position: absolute;
                    top: -5px;
                    right: -7px;
                    width: 20px;
                    text-align: center;
                    font-size: 12px;
                    border-radius: 50%;
                    color: #fff;
                    background-color: red;
                }
            }
            .userPhoto {
                margin-right: 15px;
            }
        }
        .userList_right {
            .userPhoto,
            .userList_content {
                position: relative;
                text-align: right;
                float: right;
                .contentName {
                    font-size: 14px;
                    :nth-child(2) {
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
                .contentText {
                    padding: 5px 12px;
                    border-radius: 4px;
                    display: inline-block;
                    border: 1px solid #fad8a1;
                    background-color: #fcf3e7;
                    white-space: normal;
                    word-break: break-all;
                    word-wrap: break-word;
                }
            }
            .userPhoto {
                margin-left: 15px;
            }
        }
        .helpAnswer {
            padding: 10px;
            background: #fff;
            border-bottom: 1px solid #d2bda0;
            /deep/.el-textarea__inner {
                resize: none;
                border: 1px solid #fad8a1;
            }
            /deep/.el-textarea__inner::-webkit-scrollbar {
                display: none;
            }
            > .answerSubmit {
                width: 100%;
                text-align: right;
                button {
                    // 发布按钮
                    width: 70px;
                    height: 33px;
                    margin-top: 10px;
                    border-radius: 4px;
                    border: none;
                    outline: none;
                    color: #ffffff;
                    background: linear-gradient(
                        154deg,
                        #fad8a1 0%,
                        #e3af6d 100%
                    );
                }
            }
        }
    }
}

.feedbackSubmit {
    .feedbackQuestion {
        background-color: #ffffff;
        margin-top: 45px;
        .feed-back-content {
            width: 574px;
            margin: 0 auto;
            padding-top: 10px;
            > div {
                width: 100%;
                margin-top: 20px;
                color: #e6e6e6;
                > label:nth-child(1) {
                    width: 70px;
                    margin-right: 32px;
                    float: left;
                    color: #606062;
                    text-align: right;
                }
            }
            > :nth-child(1) {
                // 反馈类型
                > div {
                    width: 420px;
                    display: flex;
                    justify-content: flex-start;
                }
                /deep/ .el-radio {
                    .el-radio__input.is-checked .el-radio__inner {
                        border-color: #fad8a1;
                        background: linear-gradient(
                            154deg,
                            #fad8a1 0%,
                            #e3af6d 100%
                        );
                    }
                    .el-radio__inner::after {
                        width: 0px;
                        height: 0px;
                    }
                    .el-radio__inner:hover {
                        border-color: #fad8a1;
                    }
                    .el-radio__input + .el-radio__label {
                        font-size: 14px;
                        color: #606062 !important;
                    }
                }
            }
            > :nth-child(2) {
                // 反馈分类
                // 下拉样式更改
                /deep/ .el-select {
                    width: 420px;
                    color: #adadb0;
                    position: relative;
                    .el-select-dropdown__wrap.el-scrollbar__wrap {
                        margin-bottom: 0px !important;
                    }
                    .el-input__inner {
                        border-radius: 4px 4px 0px 0px;
                        color: #606062;
                        border: 1px solid #fad8a1;
                    }
                    .el-popper[x-placement^="bottom"] {
                        margin-top: 0 !important;
                        left: 0px !important;
                        top: 40px !important;
                    }
                    .el-popper[x-placement^="top"] {
                        margin-bottom: 0 !important;
                    }
                    .el-popper[x-placement^="bottom"],
                    .el-popper[x-placement^="top"] {
                        position: absolute !important;

                        border-radius: 0px 0px 4px 4px;
                        border: 1px solid #fad8a1;
                        .el-select-dropdown__wrap {
                            .el-select-dropdown__item {
                                color: #adadb0;
                            }
                            .el-select-dropdown__item:hover {
                                background-color: #dbdbdb;
                            }
                            .el-select-dropdown__item.selected {
                                color: #606062;
                            }
                        }
                        .el-select-dropdown__item.hover,
                        .el-select-dropdown__item:hover {
                            background-color: #dbdbdb;
                        }
                        .popper__arrow {
                            display: none;
                        }
                    }
                }
            }
            > :nth-child(3) {
                // 反馈内容
                // height: 120px;
                text-align: top;
                textarea {
                    width: 420px;
                    resize: none;
                    font-size: 14px;
                    border-radius: 4px;
                    padding: 10px 16px;
                    color: #606062;
                    border: 1px solid #fad8a1;
                }
            }
            > :nth-child(4) {
                // 电话回访
                // checkbox样式修改
                /deep/ .el-checkbox {
                    .el-checkbox__label {
                        color: #606062;
                    }
                    .el-checkbox__input.is-checked .el-checkbox__inner,
                    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                        background: linear-gradient(
                            154deg,
                            #fad8a1 0%,
                            #e3af6d 100%
                        );
                        border-radius: 6px;
                        border-color: #dcdfe6;
                    }
                    .el-checkbox__inner {
                        border-radius: 6px;
                    }
                    .el-checkbox__input.is-focus .el-checkbox__inner,
                    .el-checkbox__inner:hover,
                    .el-checkbox__inner::after {
                        border-color: #dcdfe6;
                    }
                }
            }
        }
        .answerSubmit {
            // 发布按钮
            width: 70px;
            height: 33px;
            margin-top: 10px;
            margin-bottom: 20px;
            border-radius: 4px;
            border: none;
            outline: none;
            color: #ffffff;
            background: linear-gradient(154deg, #fad8a1 0%, #e3af6d 100%);
        }
    }
}

// 提交按钮
.el-button--warning {
    width: 108px;
    height: 36px;
    font-size: 14px;
    line-height: 0px;
    border-radius: 4px;
    background: linear-gradient(154deg, #fad8a1 0%, #e3af6d 100%);
    box-shadow: 0px 0px 5px 0px rgba(253, 196, 114, 0.6);
    outline: none;
    color: #351b08;
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .feedbackHelp {
        .userList_left,
        .userList_right {
            > .userList_content {
                width: 600px !important;
            }
        }
    }
}

@media screen and (max-width: 1000px) and (min-width: 700px) {
    .feedbackList {
        .feedbackList_content {
            margin-top: 0px;
            > div .feedbackList_text {
                width: 78%;
            }
            > div .feedbackList_btns {
                width: 18%;
            }
        }
    }
    .feedbackHelp {
        .userList_left,
        .userList_right {
            > .userList_content {
                width: 425px !important;
            }
        }
    }

    .feedbackSubmit {
        .feedbackQuestion {
            .feed-back-content {
                width: 100%;
                padding: 20px 20px 0 20px;
                > div {
                    margin-top: 0px;
                }
                .minNone {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
    .feedbackQuestion {
        margin-top: 16px !important;
    }
    .partitionTitle {
        display: none;
    }
    .feedback {
        padding-top: 10px;
    }
    .feedbackList {
        .feedbackList_content {
            margin-top: 0px;
            > div .feedbackList_text {
                width: 78%;
            }
            > div .feedbackList_btns {
                width: 18%;
            }
        }
    }
    .feedbackSubmit {
        .feedbackQuestion {
            .feed-back-content {
                width: 100%;
                padding: 20px 20px 0 20px;
                > div {
                    margin-top: 0px;
                    > label:nth-child(1) {
                        width: 100%;
                        text-align: left !important;
                    }
                }
                .minNone {
                    display: none;
                }
            }
        }
    }

    .feedbackHelp {
        .userList_left,
        .userList_right {
            > .userList_content {
                width: 200px !important;
                font-size: 14px;
            }
            .userPhoto {
                width: 35px;
                height: 35px;
            }
        }
    }
}

@media screen and (max-width: 500px) and (min-width: 391px) {
    .feedbackQuestion {
        margin-top: 16px !important;
    }
    .partitionTitle {
        display: none;
    }
    .feedback {
        padding-top: 10px;
    }
    .feedbackList {
        .feedbackList_content {
            margin-top: 0px;
            > div .feedbackList_text {
                width: 72%;
            }
            > div .feedbackList_btns {
                width: 25%;
            }
        }
    }

    .feedbackSubmit {
        .feedbackQuestion {
            .feed-back-content {
                width: 100%;
                padding: 20px 20px 0 20px;
                > div {
                    margin-top: 0px;
                    > label:nth-child(1) {
                        width: 100%;
                        text-align: left !important;
                    }
                    /deep/ .el-select {
                        width: 100% !important;
                    }
                }
                > :nth-child(3) {
                    textarea {
                        width: 100%;
                    }
                }
                > :nth-child(4) {
                    /deep/ .el-checkbox {
                        > span {
                            float: left;
                        }
                        :nth-child(1) {
                            width: 14px;
                            margin-top: 1px;
                        }
                        :nth-child(2) {
                            white-space: normal;
                            width: 90%;
                        }
                    }
                }

                .minNone {
                    display: none;
                }
            }
        }
    }

    .feedbackHelp {
        .userList_left,
        .userList_right {
            > .userList_content {
                width: 200px !important;
                font-size: 14px;
            }
            .userPhoto {
                width: 35px;
                height: 35px;
            }
        }
    }
}

@media screen and (max-width: 390px) and (min-width: 340px) {
    .feedbackQuestion {
        margin-top: 16px !important;
    }
    .partitionTitle {
        display: none;
    }
    .feedback {
        padding-top: 10px;
    }
    .feedbackList {
        .feedbackList_content {
            margin-top: 0px;
            > div .feedbackList_text {
                width: 65%;
            }
            > div .feedbackList_btns {
                width: 30%;
            }
        }
    }

    .feedbackSubmit {
        .feedbackQuestion {
            .feed-back-content {
                width: 100%;
                padding: 20px 20px 0 20px;
                > div {
                    margin-top: 0px;
                    > label:nth-child(1) {
                        width: 100%;
                        text-align: left !important;
                        /deep/ .el-select {
                            width: 100% !important;
                        }
                    }
                }
                > :nth-child(3) {
                    textarea {
                        width: 100%;
                    }
                }
                > :nth-child(4) {
                    /deep/ .el-checkbox {
                        > span {
                            float: left;
                        }
                        :nth-child(1) {
                            width: 14px;
                            margin-top: 1px;
                        }
                        :nth-child(2) {
                            white-space: normal;
                            width: 90%;
                        }
                    }
                }
                .minNone {
                    display: none;
                }
            }
        }
    }

    .feedbackHelp {
        .userList_left,
        .userList_right {
            > .userList_content {
                width: 200px !important;
                font-size: 14px;
            }
            .userPhoto {
                width: 35px;
                height: 35px;
            }
        }
    }
}
@media screen and (max-width: 339px) {
    .feedbackQuestion,
    .feedbackList_content {
        margin-top: 0px !important;
    }
    .partitionTitle {
        display: none;
    }
    .feedback {
        padding-top: 10px;
    }
    .feedbackList {
        .feedbackList_content {
            margin-top: 0px;
            > div .feedbackList_text {
                width: 60%;
            }
            > div .feedbackList_btns {
                width: 35%;
            }
        }
        .answerFeedbackBtn {
            width: 60px !important;
        }
    }

    .feedbackSubmit {
        .feedbackQuestion {
            .feed-back-content {
                width: 100%;
                padding: 20px 20px 0 20px;
                > div {
                    margin-top: 0px;
                    > label:nth-child(1) {
                        width: 100%;
                        text-align: left !important;
                        /deep/ .el-select {
                            width: 100% !important;
                        }
                    }
                }
                > :nth-child(3) {
                    textarea {
                        width: 100%;
                    }
                }
                > :nth-child(4) {
                    /deep/ .el-checkbox {
                        > span {
                            float: left;
                        }
                        :nth-child(1) {
                            width: 14px;
                            margin-top: 1px;
                        }
                        :nth-child(2) {
                            white-space: normal;
                            width: 90%;
                        }
                    }
                }
                .minNone {
                    display: none;
                }
            }
        }
    }
    .feedbackHelp {
        .userList_left,
        .userList_right {
            > .userList_content {
                width: 175px !important;
                font-size: 12px;
            }
            .userPhoto {
                width: 35px !important;
                height: 35px !important;
                > img {
                    width: 35px !important;
                    height: 35px !important;
                }
            }
        }
        .userList_left {
            .userPhoto {
                margin-right: 5px !important;
            }
        }
        .userList_right {
            .userPhoto {
                margin-left: 5px !important;
            }
        }
    }
}
</style>
