<template>
    <!-- 隔断 Start-->
    <div class="partitionTitle">
        <div :class="black == 'true'?'active':''">
            <svg v-if="black == 'true'" width="18px" height="18px" viewBox="0 0 24 24" version="1.1" >
                <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g class="blackLeft_svg" transform="translate(-661.000000, -276.000000)" fill="#606062">
                        <g  transform="translate(661.000000, 276.000000)">
                            <polygon points="17.3137085 1 18.7279221 2.41421356 8.82842712 12.3137085 18.7279221 22.2132034 17.3137085 23.627417 6 12.3137085"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
            {{title}}
        </div>
        <div class="lineBox"></div>
    </div>
    <!-- End -->
</template>
<script>

export default {
    name: "PartitionTitle",
    props:{
        title:{
            type: String,
            default:'暂无数据'
        },
        black:{
            type: String,
            default: 'false'
        }
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
 
    // 隔断标题
    .partitionTitle{
        height: 22px;
        font-size: 16px;
        color: #606062;
        .active:hover{
            color: #D5A561;
            cursor: pointer;
            .blackLeft_svg{
                fill: #D5A561 !important;
            }
        }
        >div{
            width: 12%;
            float: left;
            svg{
                margin-top: -3px;
            }
        }
        .lineBox{
            height: 1px;
            width: 88%;
            margin-top: 13.5px;
            float: right;
            background: #FAD8A1;
            opacity: 0.5;
        }
    }

    @media screen and (max-width: 1000px) and (min-width: 700px) {
        .partitionTitle{
            >div{ width: 17%;}
            >.lineBox{ width: 83% ;}
        }
    }
    @media screen and (max-width: 700px) and (min-width: 500px){
        .partitionTitle{
            >div{ width: 23%;}
            >.lineBox{ width: 77% ;}
        }
    }
    @media screen and (max-width: 500px) {
        .partitionTitle{
            font-size: 14px;
            >div{ width: 27%;}
            >.lineBox{ 
                width: 73%;
                margin:11px 0;
            ;}
        }
    }
</style>